import { all, call, fork, put, select, take } from 'redux-saga/effects';
import userActions from '../user/actions';
import { selectCompanyIDSaga } from '../../common/saga/selectors';
import PusherClient from '../../components/shared/pusherClient';
import { createPusherReduxAction, getPusherPayload, PusherActionType } from './PusherActions';
import { eventChannel } from 'redux-saga';

function createPusherChannel(companyID: string) {
  return eventChannel(emitter => {
    const realtimeBroadcast = PusherClient().subscribe(`${companyID}`);

    const eventHandler = (action: PusherActionType) => (data: any) => {
      emitter({ action, data });
    };

    // Bind all events
    for (const action of Object.values(PusherActionType)) {
      realtimeBroadcast.bind(action, eventHandler(action));
    }

    // Return unsubscribe function
    return () => {
      realtimeBroadcast.unsubscribe();
    };
  });
}

function* watchCompanyLoaded() {
  const { isCompanyLoaded } = yield select(({ user }) => user);

  if (!isCompanyLoaded) {
    yield take(userActions.SET_COMPANY);
  }

  const companyID = yield* selectCompanyIDSaga();

  const pusherChannel = yield call(createPusherChannel, companyID);

  while (true) {
    const payload = yield take(pusherChannel);
    const action = createPusherReduxAction(payload.action, payload.data);
    yield put(action);
  }
}

export default function* rootSaga() {
  yield all([fork(watchCompanyLoaded)]);
}
