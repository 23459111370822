import { put, select } from 'redux-saga/effects';
import { DeleteClientContractAction, settingPageActions } from '../actions';
import api from '../../../../../../services/api';
import MODAL_MODE from '../../../../common/constants';
import { getTriggerReFetchClients } from '../selectors';

export function* deleteClientContract(action: DeleteClientContractAction) {
  const { clientContractID } = action.payload;

  if (!clientContractID) {
    return;
  }

  try {
    yield api.delete(`/v1/client-contracts/${clientContractID}`);
    yield put(
      settingPageActions.setClientContractModalMode({ mode: MODAL_MODE.create, open: false }),
    );

    const triggerReFetchClients = yield select(getTriggerReFetchClients);
    yield put(settingPageActions.setState({ triggerReFetchClients: !triggerReFetchClients }));
  } catch (e) {
    console.error('Delete client contract error:', e);
  }
}
