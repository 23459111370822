import { call, put, select } from 'redux-saga/effects';
import { getCompanyLibrary } from '../selectors';
import { CompanyLibraryState } from '../reducers';
import { setState } from '../actions';
import { loadFileListWorker } from './loadFileListWorker';

export function* loadMoreWorker() {
  const { pagination } = (yield select(getCompanyLibrary)) as CompanyLibraryState;

  const newPagination = {
    ...pagination,
    current: pagination.current + 1,
  };
  yield put(setState({ pagination: newPagination }));
  yield call(loadFileListWorker);
}
