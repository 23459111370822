const config = {
  local: {
    apiURL: 'http://localhost:3300',
    bartUrl: 'http://localhost:9990',
    automatedTestUrl: 'http://localhost:3000',
    integrationsUrl: 'https://dev-guardian-mobility-integration.svc.airtrail.ca',
    enableServiceWorker: false,
    launchDarklyKey: '635880b9d5e265119fdb8a8f',
    googleMapsKey: '',

    // pusher
    pusher_app_id: '1662998',
    pusher_key: '4a1a33be18fcd1273357',
    pusher_cluster: 'us3',

    remotePrinterURL: 'http://localhost:8080',
  },
  dev: {
    apiURL: 'https://dev-api.svc.airtrail.ca',
    automatedTestUrl: 'https://dev-dashboard.svc.airtrail.ca/',
    bartUrl: 'https://dev-bart-sync.svc.airtrail.ca',
    integrationsUrl: 'https://dev-guardian-mobility-integration.svc.airtrail.ca',
    enableServiceWorker: false,
    launchDarklyKey: '635880b9d5e265119fdb8a8f',
    googleMapsKey: 'AIzaSyACvLmEXqO7ZNvmUaYv6ebv1YFnPwlCyJU',

    // pusher
    pusher_app_id: '1543862',
    pusher_key: 'b589ec9d9823a1e5d5cb',
    pusher_cluster: 'us3',
    remotePrinterURL: 'https://dev-remote-printer.svc.airtrail.ca',
  },
  prod: {
    apiURL: 'https://prod-api.svc.airtrail.ca',
    bartUrl: 'https://prod-bart-sync.svc.airtrail.ca',
    integrationsUrl: 'https://prod-guardian-mobility-integration.svc.airtrail.ca',
    enableServiceWorker: false,
    launchDarklyKey: '635880b9d5e265119fdb8a90',
    googleMapsKey: 'AIzaSyACvLmEXqO7ZNvmUaYv6ebv1YFnPwlCyJU',

    // pusher
    pusher_app_id: '1543864',
    pusher_key: '321ce0556e65ea014efd',
    pusher_cluster: 'us3',

    remotePrinterURL: 'https://prod-remote-printer.svc.airtrail.ca',
  },
  prod2: {
    apiURL: 'https://prod2.api.airtrail.ca',
    bartUrl: 'https://prod2-bart-sync.svc.airtrail.ca',
    integrationsUrl: 'https://prod2-guardian-mobility-integration.svc.airtrail.ca',
    enableServiceWorker: false,
    launchDarklyKey: '635880b9d5e265119fdb8a90',
    googleMapsKey: 'AIzaSyACvLmEXqO7ZNvmUaYv6ebv1YFnPwlCyJU',
  },
};

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'dev';
export const AUTOTEST_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'local';

export const getAPIURL = () => process.env.REACT_APP_API_URL || config[ENVIRONMENT].apiURL;

export const getBartUrl = () => config[ENVIRONMENT].bartUrl;

export const getIntegrationsUrl = () => config[ENVIRONMENT].integrationsUrl;

export const getEnableServiceWorker = () => config[ENVIRONMENT].enableServiceWorker;

export const getLaunchDarklyKey = () => config[ENVIRONMENT].launchDarklyKey;

export const getGoogleMapsKey = () => config[ENVIRONMENT].googleMapsKey;

export const getPusherKeys = () => config[ENVIRONMENT];

export const getAutomatedTestUrl = () => config[AUTOTEST_ENVIRONMENT].automatedTestUrl;

export const getAutoTestPwd = () => process.env.REACT_APP_AUTOTEST_PWD || 'paperless';

export const getRemotePrinterURL = () =>
  config[ENVIRONMENT].remotePrinterURL || 'https://dev-remote-printer.svc.airtrail.ca';
