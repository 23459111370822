import { UserSummary, ProfileMin } from '../../../../../common/types/user';

export enum FileStatus {
  Active = 'active',
  Archived = 'archived',
  Removed = 'removed',
  error = 'error',
}

export enum RelatedEntityType {
  User = 'user',
  Aircraft = 'aircraft',
  AircraftLog = 'aircraft_log',
  CompanyDocument = 'company_document',
  TrainingItem = 'training_item',
}

export interface RelatedEntity {
  Type: RelatedEntityType;
  ID: string;
}

export interface GenerationInfo {
  ModifiedDate: Date;
  Generation: number;
  ModifiedBy: string;
  ProfileMin: ProfileMin;
}

export interface File {
  ID: string;
  CompanyID: string;
  CreatedAt: string;
  UpdatedAt?: string;
  UpdatedBy?: string;

  Notes?: string;
  Status: FileStatus;

  CurrentGeneration: number;
  Generations: number[];
  GenerationsInfo: GenerationInfo[];
  MimeType: string;
  Size: number;
  FileClientName: string;
  FileName: string;
  Ext: string;
  Url: string;
  RelatedEntities: RelatedEntity[];
  UpdatedByUser: UserSummary;
  ExpirationDate: string
}
