import { getPusherKeys } from 'config';
import Pusher from 'pusher-js';

export default function PusherClient() {
  const keys = getPusherKeys();

  const pusher = new Pusher(keys.pusher_key, {
    cluster: keys.pusher_cluster,
    encrypted: true,
  });

  return pusher;
}
