import { AddClientContractAction, settingPageActions } from '../actions';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../../../../services/api';
import { ClientContractForm } from '../types';
import { put, select } from 'redux-saga/effects';
import MODAL_MODE from '../../../../common/constants';
import { getTriggerReFetchClients } from '../selectors';

type PutClientContractRequest = {
  ClientContractID: string;
  ClientID: string;
} & ClientContractForm;

export function* saveClientContract(action: AddClientContractAction) {
  const { clientID, clientContractID, form } = action.payload;

  const request: PutClientContractRequest = {
    ClientContractID: clientContractID || uuidv4(),
    ClientID: clientID,
    Name: form.Name,
    Number: form.Number,
    Type: form.Type,
    Year: form.Year,
    NAICS: form.NAICS,
    SBAThreshold: form.SBAThreshold,
    Comments: form.Comments,
  };

  try {
    yield api.put(`/v1/client-contracts`, request);
    yield put(
      settingPageActions.setClientContractModalMode({ mode: MODAL_MODE.create, open: false }),
    );

    const triggerReFetchClients = yield select(getTriggerReFetchClients);
    yield put(settingPageActions.setState({ triggerReFetchClients: !triggerReFetchClients }));
  } catch (e) {
    console.error('addClientContract', e);
  }
}
