import {
  selectAircraftScheduleTimelineEntriesSaga,
  selectContextMenuClickPosition,
  selectCopyItemSaga,
  selectCrewScheduleTimelineEntriesSaga,
  selectCutItemSaga,
  selectDutyScheduleTimelineEntriesSaga,
} from '../../selectors-saga';
import { CrewTimelineEntryType } from '../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { call, put } from 'redux-saga/effects';
import { scheduleActions } from '../../actions';
import { CrewScheduleTimelineEntry } from '../../../../../../../common/types/timeline/CrewScheduleTimelineEntry';
import api from '../../../../../../../services/api';
import { fetchAircraftScheduleEntry, fetchEntry } from '../timelineEntries/fetchEntry';
import { CrewScheduleEntry } from 'redux/pages/crew-scheduling-v3/scheduleEntries/types/CrewScheduleEntry';
import { selectCompanyIDSaga } from '../../../../../../../common/saga/selectors';
import { showErrorModal } from '../../../legacy/utils/errHandling';
import { DutyTimelineEntry } from '../../../../../../../common/types/timeline/DutyTimelineEntry';
import { DutyScheduleEntry } from 'redux/pages/crew-scheduling-v3/scheduleEntries/types/DutyScheduleEntry';
import { selectFeatureFlagsSaga } from '../../../../../../../redux/common/selectors-saga';
import { CrewTimelineItem } from '../../../components/Timeline/CrewTimeline/generator';
import { AircraftTimelineItem } from '../../../components/Timeline/AircraftTimeline/generator';
import { ContextMenuClickPosition } from '../../../types/timeline';
import { AircraftSchedule } from '../../../../../../../common/types/aircraftSchedule';
import { AircraftTimelineEntry } from '../../../../../../../common/types/timeline/AircraftTimelineEntry';
import { adjustEntryDetailedFlightTimes } from '../../../legacy/utils/utils';
import { NEW_ITEM_ID } from '../../../components/Timeline/constants';
import { adjustDutyTimes } from '../../../helpers';
import moment from 'moment-timezone';
import { parseGroupID } from '../../../components/Timeline/helper';
import {
  ContractTimelineEntry,
  PilotContractTimelineEntry,
} from '../../../../../../../common/types/timeline/AircraftContractTimelineEntry';
import { ContractTimelineItem } from '../../../components/Timeline/ContractTimeline/generator';

export function* handlePasteTimelineItem() {
  const cutItem = yield* selectCutItemSaga();
  const copyItem = yield* selectCopyItemSaga();
  const position = yield* selectContextMenuClickPosition();

  if (copyItem) {
    yield call(handleCopyAndPaste, copyItem, position);
  } else if (cutItem) {
    yield call(handleCutAndPaste, cutItem, position);
  }
}

function* handleCutAndPaste(
  cutItem: CrewTimelineItem | AircraftTimelineItem,
  position: ContextMenuClickPosition,
) {
  const companyID = yield* selectCompanyIDSaga();

  const currentItemStart = cutItem.start_time;
  const currentItemEnd = cutItem.end_time;
  let [newItemStart, newItemEnd] = calculateNewItemTime(
    currentItemStart,
    currentItemEnd,
    position.time,
  );
  const selectedGroupEntryID = parseGroupID(position.groupID).entryID;

  switch (cutItem.entryType) {
    case CrewTimelineEntryType.CrewSchedule:
      const existedEntries = yield* selectCrewScheduleTimelineEntriesSaga();
      const updatedEntries = existedEntries.map(entry => {
        if (entry.ID === cutItem.id) {
          const updatedEntry: CrewScheduleTimelineEntry = {
            ...entry,
            StartTime: moment(newItemStart).format(),
            EndTime: moment(newItemEnd).format(),
            UserID: selectedGroupEntryID,
          };
          return updatedEntry;
        }
        return entry;
      });

      // here we should patch the entry
      try {
        const fullEntry = (yield fetchEntry(
          CrewTimelineEntryType.CrewSchedule,
          cutItem.id,
          false,
        )) as CrewScheduleEntry;
        fullEntry.StartTime = moment(newItemStart).format();
        fullEntry.EndTime = moment(newItemEnd).format();
        fullEntry.UserID = selectedGroupEntryID;
        yield api.patch(
          `/v1/companies/${companyID}/crewschedule/update/${fullEntry.ID}`,
          fullEntry,
        );
      } catch (error) {
        showErrorModal('Failed to update the entry', error);
      }

      yield put(scheduleActions.setState({ crewScheduleTimelineEntries: updatedEntries }));

      break;
    case CrewTimelineEntryType.DutySchedule:
      const featureFlags = yield* selectFeatureFlagsSaga();

      const existedDutyScheduleEntries = yield* selectDutyScheduleTimelineEntriesSaga();

      const updatedDutyScheduleEntries = existedDutyScheduleEntries.map(entry => {
        if (entry.ID === cutItem.id) {
          return adjustDutyTimes(
            { ...entry, IsAwaitingForUpdate: true, UserID: selectedGroupEntryID },
            cutItem.start_time,
            cutItem.end_time,
            newItemStart,
            newItemEnd,
            featureFlags,
          ) as DutyTimelineEntry;
        }
        return entry;
      });

      yield put(
        scheduleActions.setState({ dutyScheduleTimelineEntries: updatedDutyScheduleEntries }),
      );

      // here we should patch the entry
      try {
        let fullEntry = (yield fetchEntry(
          CrewTimelineEntryType.DutySchedule,
          cutItem.id,
          false,
        )) as DutyScheduleEntry;

        fullEntry = adjustDutyTimes(
          fullEntry,
          cutItem.start_time,
          cutItem.end_time,
          newItemStart,
          newItemEnd,
          featureFlags,
        ) as DutyScheduleEntry;
        fullEntry.UserID = selectedGroupEntryID;

        const withUpdatedDetailedFlightTimes = adjustEntryDetailedFlightTimes(fullEntry);

        yield api.patch(
          `/v1/users/${fullEntry.UserID}/duty-schedule/${fullEntry.ID}/v2`,
          withUpdatedDetailedFlightTimes,
        );
      } catch (error) {
        showErrorModal('Failed to update the entry', error);
      }

      break;
    case AircraftTimelineEntryType.AircraftSchedule:
      const existedAircraftScheduleEntries = yield* selectAircraftScheduleTimelineEntriesSaga();
      const updatedASEntries = existedAircraftScheduleEntries.map(entry => {
        if (entry.VirtualID == cutItem.aircraftEntry.VirtualID) {
          const updatedEntry: AircraftTimelineEntry = {
            ...entry,
            StartTime: new Date(newItemStart),
            EndTime: new Date(newItemEnd),
            AssignedAircraftIDs: [selectedGroupEntryID],
          };
          return updatedEntry;
        }
        return entry;
      });
      yield put(scheduleActions.setState({ aircraftScheduleTimelineEntries: updatedASEntries }));

      try {
        let fullASEntry = (yield fetchAircraftScheduleEntry(
          cutItem.entryType,
          cutItem,
          false,
        )) as AircraftSchedule;

        const crewScheduleEntries = fullASEntry.RelatedCrewScheduleEntries
          ? fullASEntry.RelatedCrewScheduleEntries
          : [];
        const crewScheduleEntryIDs = [];
        crewScheduleEntries.forEach(entry => {
          crewScheduleEntryIDs.push(entry.ID);
          return entry;
        });
        const deletePayload = {
          CrewScheduleEntryIds: crewScheduleEntryIDs,
        };

        yield api.post(`/v1/companies/${companyID}/crewschedule/bulk-delete`, deletePayload);

        const bulkCreatePayload: Partial<
          AircraftSchedule | { UserIDs: string[]; AircraftID: string }
        > = {
          Notes: fullASEntry.Notes,
          UserIDs: fullASEntry.UserID,
          AircraftID: selectedGroupEntryID,
          StartAirportID: fullASEntry.StartAirportID,
          EndAirportID: fullASEntry.EndAirportID,
          CompanyScheduleTypeID: fullASEntry.CompanyScheduleTypeID,
          Subparts: fullASEntry.Subparts,
          StartTime: new Date(newItemStart),
          EndTime: new Date(newItemEnd),
        };
        yield api.post(`/v1/companies/${companyID}/crewschedule/bulk-create`, bulkCreatePayload);
      } catch (error) {
        showErrorModal('Failed to cut & paste the entry', error);
      }
      break;
    case CrewTimelineEntryType.DutyTimes:
      console.log('not implemented yet');
      break;
  }
}

function* handleCopyAndPaste(
  copyItem: CrewTimelineItem | AircraftTimelineItem,
  position: ContextMenuClickPosition,
) {
  const companyID = yield* selectCompanyIDSaga();

  const currentItemStart = copyItem.start_time;
  const currentItemEnd = copyItem.end_time;
  let [newItemStart, newItemEnd] = calculateNewItemTime(
    currentItemStart,
    currentItemEnd,
    position.time,
  );
  const selectedGroupEntryID = parseGroupID(position.groupID).entryID;

  switch (copyItem.entryType) {
    case CrewTimelineEntryType.CrewSchedule:
      const existedEntries = yield* selectCrewScheduleTimelineEntriesSaga();
      const existedCrewScheduleEntry = existedEntries.find(entry => entry.ID === copyItem.id);
      const newCrewScheduleEntry: CrewScheduleTimelineEntry = {
        ...existedCrewScheduleEntry,
        ID: NEW_ITEM_ID,
        StartTime: moment(newItemStart).format(),
        EndTime: moment(newItemEnd).format(),
        UserID: selectedGroupEntryID,
      };

      const updatedEntries = [...existedEntries, newCrewScheduleEntry];
      yield put(scheduleActions.setState({ crewScheduleTimelineEntries: updatedEntries }));

      try {
        const fullEntry = (yield fetchEntry(
          CrewTimelineEntryType.CrewSchedule,
          copyItem.id,
          false,
        )) as CrewScheduleEntry;

        fullEntry.StartTime = moment(newItemStart).format();
        fullEntry.EndTime = moment(newItemEnd).format();
        fullEntry.UserID = selectedGroupEntryID;
        yield api.post(`/v1/companies/${companyID}/crewschedule/create`, fullEntry);
      } catch (err) {
        showErrorModal('Failed to copy the entry', err);
      }
      break;
    case CrewTimelineEntryType.DutySchedule:
      const existedDSEntries = yield* selectDutyScheduleTimelineEntriesSaga();
      const featureFlags = yield* selectFeatureFlagsSaga();

      const existedDSEntry = existedDSEntries.find(entry => entry.ID === copyItem.id);

      const updatedDSEntry = adjustDutyTimes(
        { ...existedDSEntry },
        copyItem.start_time,
        copyItem.end_time,
        newItemStart,
        newItemEnd,
        featureFlags,
      ) as DutyTimelineEntry;
      updatedDSEntry.ID = NEW_ITEM_ID;
      updatedDSEntry.IsAwaitingForUpdate = true;
      updatedDSEntry.UserID = selectedGroupEntryID;

      const updatedDutyScheduleEntries = [...existedDSEntries, updatedDSEntry];
      yield put(
        scheduleActions.setState({ dutyScheduleTimelineEntries: updatedDutyScheduleEntries }),
      );

      try {
        let fullEntry = (yield fetchEntry(
          CrewTimelineEntryType.DutySchedule,
          copyItem.id,
          false,
        )) as DutyScheduleEntry;
        fullEntry = adjustDutyTimes(
          fullEntry,
          copyItem.start_time,
          copyItem.end_time,
          newItemStart,
          newItemEnd,
          featureFlags,
        ) as DutyScheduleEntry;
        fullEntry.ID = null;
        fullEntry.UserID = selectedGroupEntryID;

        yield api.post(`/v1/users/${fullEntry.UserID}/duty-schedule/v2`, fullEntry);
      } catch (err) {
        showErrorModal('Failed to copy the entry', err);
      }

      break;
    case CrewTimelineEntryType.DutyTimes:
      console.log('It`s probably should not be implemented');
      break;
    case AircraftTimelineEntryType.AircraftSchedule:
      const existedAircraftScheduleEntries = yield* selectAircraftScheduleTimelineEntriesSaga();
      const existedTimelineEntry = existedAircraftScheduleEntries.find(
        entry => entry.VirtualID === copyItem.aircraftEntry.VirtualID,
      );
      const newASTimelineEntry = {
        ...existedTimelineEntry,
        StartTime: new Date(newItemStart),
        EndTime: new Date(newItemEnd),
        AssignedAircraftIDs: [selectedGroupEntryID],
      };
      newASTimelineEntry.VirtualID = GenerateVirtualID(newASTimelineEntry);

      const updatedASEntries = [...existedAircraftScheduleEntries, newASTimelineEntry];
      yield put(scheduleActions.setState({ aircraftScheduleTimelineEntries: updatedASEntries }));

      try {
        let fullASEntry = (yield fetchAircraftScheduleEntry(
          copyItem.entryType,
          copyItem,
          false,
        )) as AircraftSchedule;

        const bulkCreatePayload: Partial<
          AircraftSchedule | { UserIDs: string[]; AircraftID: string }
        > = {
          Notes: fullASEntry.Notes,
          UserIDs: fullASEntry.UserID,
          AircraftID: selectedGroupEntryID,
          StartAirportID: fullASEntry.StartAirportID,
          EndAirportID: fullASEntry.EndAirportID,
          CompanyScheduleTypeID: fullASEntry.CompanyScheduleTypeID,
          Subparts: fullASEntry.Subparts,
          StartTime: new Date(newItemStart),
          EndTime: new Date(newItemEnd),
        };
        yield api.post(`/v1/companies/${companyID}/crewschedule/bulk-create`, bulkCreatePayload);
      } catch (error) {
        showErrorModal('Failed to copy the entry', error);
      }
      break;
  }
}

export const GenerateVirtualID = (entry: AircraftTimelineEntry): string => {
  // Convert time values to string
  const startTimeUnix = Math.floor(entry.StartTime.getTime() / 1000);
  const endTimeUnix = Math.floor(entry.EndTime.getTime() / 1000);

  // Join AssignedUserIDs and AssignedAircraftIDs into single strings
  const userIDsStr = entry.AssignedUserIDs.join(',');
  const aircraftIDsStr = entry.AssignedAircraftIDs.join(',');

  // Combine all fields into one string with delimiters
  return `${startTimeUnix}|${endTimeUnix}|${userIDsStr}|${aircraftIDsStr}`;
};

export const GenerateVirtualIDForPilotSchedule = (entry: ContractTimelineItem): string => {
  // Convert time values to string
  const startTimeUnix = Math.floor(entry.start_time / 1000);
  const endTimeUnix = Math.floor(entry.end_time / 1000);

  // Join AssignedUserIDs and AssignedAircraftIDs into single strings
  const userIDsStr = entry.userIDs?.length > 0 ? entry.userIDs.join(',') : entry.userID;
  const groupedByID = entry.clientContractID;

  // Combine all fields into one string with delimiters
  return `${startTimeUnix}|${endTimeUnix}|${userIDsStr}|${groupedByID}`;
};

function calculateNewItemTime(
  currentItemStart: number,
  currentItemEnd: number,
  time: number,
): [number, number] {
  const startHours = moment(currentItemStart).hours();
  const startMinutes = moment(currentItemStart).minutes();

  const timeDifference = moment(currentItemEnd).diff(moment(currentItemStart), 's');

  const newStart = moment(time).set({ hour: startHours, minute: startMinutes });
  const newEnd = newStart.clone().add(timeDifference, 's');
  return [newStart.valueOf(), newEnd.valueOf()];
}
