import { CreateAircraftGroupAction } from '../actions';
import api from '../../../../../../../../services/api';
import { call, put, select } from 'redux-saga/effects';
import { getCompanyID } from '../../../../../../../../redux/selectors';
import { loadGroupWorker } from './loadGroupWorker';

export function* createGroupWorker(action: CreateAircraftGroupAction) {
  const { payload } = action;
  const companyID = yield select(getCompanyID);

  try {
    yield api.post(`/v1/companies/${companyID}/aircraft-group/create`, payload);
    yield call(loadGroupWorker);
  } catch (err) {
    console.log(err);
  }
}
