import { SaveVirtualAircraftContractEntryAction, scheduleActions } from '../../actions';
import api from '../../../../../../../services/api';
import { put } from 'redux-saga/effects';
import MODAL_MODE from '../../../../../common/constants';

export function* saveVirtualAircraftContract(action: SaveVirtualAircraftContractEntryAction) {
  const { form, mode } = action.payload;

  try {
    yield api.post('/v1/contracts/virtual/aircraft', {
      CurrentAircraftContractIDs: form.aircraftContractIDs,
      NewValues: {
        AircraftIDs: form.aircraftIDs,
        ClientContractID: form.clientContractID,
        StartDate: form.startDate,
        EndDate: form.endDate,
      },
    });

    yield put(
      scheduleActions.setGroupedAircraftContractModalMode({
        open: false,
        mode: MODAL_MODE.create,
      }),
    );
  } catch (err) {
    console.log('failed to post a new form item', err);
  } finally {
  }
}
