import api from './api';
import { clearCookies } from '../utils';
import Jwt from './Jwt';

export async function requestToken(email, password) {
  return api
    .post('/auth/login', { email, password })
    .then(response => {
      Jwt.set(response.data.AccessToken);
      localStorage.setItem('refreshToken', response.data.RefreshToken);
      return true;
    })
    .catch(console.log);
}

export async function logout() {
  clearCookies();
  Jwt.clear();
  const refreshToken = localStorage.getItem('refreshToken');

  if (refreshToken) {
    try {
      await api.post('/auth/logout', {}, {
        headers: {
          'Authorization': `Bearer ${refreshToken}`
        }
      });
      localStorage.removeItem('refreshToken');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  } else {
    console.log("No refresh token available.");
  }
}


export async function getMyAccount() {
  return api
    .get('/v1/users/me')
    .then(response => {
      return response.data.Data;
    })
    .catch(console.log);
}

export async function getCompany(id) {
  return api
    .get(`/v1/companies/${id}`)
    .then(response => {
      return response.data.Data;
    })
    .catch(console.log);
}
